function Home() {
    return (
        <div className="w-8/12 md:w-7/12 lg:w-6/12 xl:w-3/12 2xl:w-3/12 pb-6 sm:pb-12 md:pr-0 pr-5 2xl:text-lg text-sm text-justify contentpage self-end">
            Passionate software developer specializing in web development, with a keen eye for customization and a strong focus on aesthetics. Experienced across all phases of the development lifecycle, from system design to deployment, with expertise in both frontend and backend development for mobile and desktop platforms.
            < br/>
            You can find most of my work on <a href="https://github.com/cthdarren/" target="_blank" rel="noreferrer"><u>Github.</u></a>
            <br />
            <br />
            Currently, I am:
            <ol className="list-disc list-outside ml-5">
                <li>
                    Studying Computer Science at SUTD 
                </li>
                <li>
                    working on <a href="https://github.com/cthdarren/cli-type" target="_blank" rel="noreferrer"><u>cli-type</u></a>, a typing test at your fingertips, in your terminal. <br />
                </li>
                <li>
                    writing an alternative Text-based User Interface for the Youtube Music client, <a rel="noreferrer" href="https://github.com/cthdarren/ytmusic-tui" target="_blank"><u>ytmusic-tui</u></a>
                </li>
                <li>
                    developing <a href="https://github.com/cthdarren/Splitway" target="_blank" rel="noreferrer"><u>Splitway</u></a>. a bill splitting app to streamline expense management between groups and friends, making it easier to track and settle shared costs.
                </li>
            </ol>
        </div>
    );
}

export default Home;
